import { CheckCircle } from '@mui/icons-material';
import ActionButtonRounded from 'Components/ActionButtonRounded';
import HistoryRowSubHeader from 'Components/HistoryRowSubHeader';
import { actions } from 'Store/myapp/actions';
import { formatPartialDate, nowPartialDate } from 'neuro-utils';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

const RecurringTasksList = ({
  platform,
  recurringTasks,
  showEndButton,
}: {
  platform: Platform;
  recurringTasks: ITaskList[];
  showEndButton?: boolean;
}) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const fm = (id: string) => formatMessage({ id });
  const [loading, setLoading] = React.useState<boolean>(false);

  const handleEndClick = async (taskList: ITaskList) => {
    setLoading(true);
    await actions.updateTasklist({ ...taskList, deadline: nowPartialDate() }, dispatch);
    setLoading(false);
  };

  return (
    <div style={{ marginBottom: '2rem' }}>
      <div>
        <HistoryRowSubHeader header={fm('myService.recurringTasks')} fontSize="small" />
      </div>
      <div style={{ padding: 1 }}>
        {recurringTasks.map((rt) => {
          const tasks = rt.tasks?.map((t) => fm(`myService.${platform}.opts.${t}`));
          if (!tasks) return;
          return (
            <div
              key={rt.id}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                margin: '0.5rem 0',
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <CheckCircle style={{ color: '#7BA015', margin: '0 1rem 0 0.3rem' }} />
                <div>
                  <div>{tasks.join(', ')}</div>
                  <div>{`${fm(`myService.opts.${rt.weeklySurveyInterval}`)}, ${formatMessage(
                    { id: 'myService.until' },
                    { until: formatPartialDate(rt.deadline) },
                  )}`}</div>
                </div>
              </div>
              {showEndButton && (
                <div>
                  <ActionButtonRounded
                    text="myService.endRecurring"
                    filled={false}
                    uppercase={false}
                    width={9.6}
                    height={3}
                    fontSize={16}
                    onClick={() => handleEndClick(rt)}
                    loading={loading}
                  />
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RecurringTasksList;
