import * as React from 'react';
import { useIntl } from 'react-intl';

import { useBlocker } from './blocker';
import ConfirmationDialog from 'Components/ConfirmationDialog';

const NavigationBlocker = ({ blocked, children }: INavigationBlocker) => {
  const [alertOpen, setAlertOpen] = React.useState<boolean>(false);

  useBlocker(() => {
    setAlertOpen(true);
  }, blocked);

  const { formatMessage } = useIntl();

  return (
    <>
      <ConfirmationDialog
        open={alertOpen}
        text={formatMessage({ id: 'general.saveOrCancel' })}
        onlyConfirm
        centeredText
        confirm={{ callback: () => setAlertOpen(false), text: 'general.ok' }}
      />
      {children}
    </>
  );
};

interface INavigationBlocker {
  blocked: boolean;
  children: JSX.Element;
}

export default NavigationBlocker;
