import { keys, length, pick, pickBy } from 'ramda';

export const neuroimagingCharacteristics = [
  'increasedSignalOnSagittalT2Weighted',
  'centralCordPredominance',
  'gadoliniumEnchancementOfTheLesionsOnT1Seq',
  'rostralExtensionOfTheLesionIntoTheBrainstem',
  'cordExpansionSwelling',
  'decresedSignalOnT1Seq',
  'longitudinallyExtensiveCordAtrophy',
  'unilateralOrBilateralIncreasedT2Signal',
  'lesionsInvolvingTheDorsalMedulla',
  'periependymalSurfacesOfTheFourthVentricle',
  'lesionsInvolvingTheHypothalamus',
  'largeConfluentUnilateralOrBilateralLesions',
  'longDiffuseHeterogeneousOrEdematousLesions',
  'longCoricospinalTractLesions',
  'extensivePeripendymalBrainLesions',
];

export const redFlags = [
  'progressiveOverallClinicalCourse',
  'atypicalTimeToAttackNadir',
  'partialTransverseMyelitis',
  'presenceOfCSFOligoclonalBands',
  'sarcoidosisEstablishedOrSuggestiveFindings',
  'cancerEstablishedOrSuggestiveFindings',
  'chronicInfectionEstablishedOrSuggestiveFindings',
  'lesionsWithOrientationPerpendicularToSurface',
  'lesionsAdjacentToLateralVentricle',
  'juxtacorticalLesionsInvolvingSubUFibers',
  'corticalLesions',
  'lesionsWithPesistentGadoliniumEnhancement',
  'lesionsCompleteVertebralSegmentsOnSagittalT2Seq',
  'lesionsLocatedPredominantly',
  'diffuseIndistinctSignalChangeOnT2Seq',
];

/**
 * Number of certain fields in document with values equal to given answer
 * @param d: INMOSDCriteria
 * @param fields: Array<string>
 * @param ans: TYesNoUnknown
 */
export const numberOfGivenAnswers = (d: INMOSDCriteria, fields: Array<string>, ans: TYesNoUnknown): number =>
  length(keys(pickBy((v) => v === ans, pick(fields, d))));

/**
 * Ohjelma päättelee tämä edellä olevien vastausten perusteella:
 *
 * "Kyllä" tulos edellyttää , että:
 * - "Optic neuritis"="Kyllä", tai
 * - "Acute myelitis"="Kyllä", tai
 * - "Area postrema syndrome: episode of otherwise unexplained hiccups or nausea and vomiting"="Kyllä", tai
 * - "Acute brainstem syndrome"="Kyllä", tai
 * - "Symptomatic narcolepsy or acute diencephalic clinical syndrome with NMOSD-typical diencephalic MRI lesions"="Kyllä", tai
 * - "Symptomatic cerebral syndrome with NMOSD-typical brain lesions"="Kyllä"
 *
 * "Ei" tulos edellyttää , että:
 * - "Optic neuritis"="Ei", ja
 * - "Acute myelitis"="Ei", ja
 * - "Area postrema syndrome: episode of otherwise unexplained hiccups or nausea and vomiting"="Ei", ja
 * - "Acute brainstem syndrome"="Ei", ja
 * - "Symptomatic narcolepsy or acute diencephalic clinical syndrome with NMOSD-typical diencephalic MRI lesions"="Ei", ja
 * - "Symptomatic cerebral syndrome with NMOSD-typical brain lesions"="Ei"
 *
 * Muutoin tulos on "Ei tiedossa"
 * @param d: INMOSDCriteria
 * @returns: TYesNoUnknown
 */
export const atLeastOneCoreClinicalCharacteristicWithAQP4 = (d: INMOSDCriteria): TYesNoUnknown => {
  if (d.opticNeuritis === 'yes') return 'yes';
  if (d.acuteMyelitis === 'yes') return 'yes';
  if (d.areaPostremaSyndrome === 'yes') return 'yes';
  if (d.acuteBrainstemSyndrome === 'yes') return 'yes';
  if (d.symptomaticNarcolepsy === 'yes') return 'yes';
  if (d.symptomaticCerebralSyndrome === 'yes') return 'yes';

  if (
    d.opticNeuritis === 'no' &&
    d.acuteMyelitis === 'no' &&
    d.areaPostremaSyndrome === 'no' &&
    d.acuteBrainstemSyndrome === 'no' &&
    d.symptomaticNarcolepsy === 'no' &&
    d.symptomaticCerebralSyndrome === 'no'
  )
    return 'no';
  return 'unknown';
};

/**
 * Ohjelma päättelee tämä edellä olevien vastausten perusteella.
 * "Kyllä" edellyttää, että "AQP4-IcG result using best available detection method (cell-based assay strongly recommended)"="Positive"
 * "Ei" edellyttää, että "AQP4-IcG result using best available detection method (cell-based assay strongly recommended)"="Negative" tai "Ei tiedossa"
 *
 * @param d: INMOSDCriteria
 * @returns: TYesNoUnknown
 */
export const positiveTestForAQP4 = (d: INMOSDCriteria): TYesNo => {
  if (d.AQP4IgGResult === 'positive') return 'yes';
  if (d.AQP4IgGResult === 'negative' || d.AQP4IgGResult === 'unknown') return 'no';
  return 'no';
};

/**
 * Ohjelma päättelee tämä edellä olevien vastausten perusteella:
 *
 * "Kyllä" tulos edellyttää , että:
 * - "At least 1 core clinical characteristic"="Kyllä", ja
 * - "Positive test for AQP4-IgG using best available detection method (cell-based assay strongly recommended)"="Kyllä", ja
 * - "Exclusion of alternative diagnoses"="Kyllä"
 *
 * "Ei" tulos edellyttää , että:
 * - "At least 1 core clinical characteristic"="Ei", tai
 * - "Positive test for AQP4-IgG using best available detection method (cell-based assay strongly recommended)"="Ei", tai
 * - "Exclusion of alternative diagnoses"="Ei"
 *
 * Muutoin tulos on "Ei tiedossa"
 * @param d: INMOSDCriteria
 * @returns: TYesNoUnknown
 */
export const diagnosisCriteriaAreFullfilledWithAQP4 = (d: INMOSDCriteria): TYesNoUnknown => {
  if (
    atLeastOneCoreClinicalCharacteristicWithAQP4(d) === 'yes' &&
    positiveTestForAQP4(d) === 'yes' &&
    d.exclusionOfAlternativeDiagnosesWithAQP4IgG === 'yes'
  )
    return 'yes';
  if (atLeastOneCoreClinicalCharacteristicWithAQP4(d) === 'no') return 'no';
  if (positiveTestForAQP4(d) === 'no') return 'no';
  if (d.exclusionOfAlternativeDiagnosesWithAQP4IgG === 'no') return 'no';
  return 'unknown';
};

/**
 * Ohjelma päättelee tämä edellä olevien vastausten perusteella:
 *
 * "Kyllä" tulos edellyttää , että vähintään kaksi seuraavista on "Kyllä"
 * - "Optic neuritis"
 * - "Acute myelitis"
 * - "Area postrema syndrome: episode of otherwise unexplained hiccups or nausea and vomiting"
 * - "Acute brainstem syndrome"="Kyllä", tai
 * - "Symptomatic narcolepsy or acute diencephalic clinical syndrome with NMOSD-typical diencephalic MRI lesions"
 * - "Symptomatic cerebral syndrome with NMOSD-typical brain lesions"
 *
 * "Ei" tulos edellyttää , että vähintään viisi seuraavista on "Ei":
 * - "Optic neuritis"
 * - "Acute myelitis"
 * - "Area postrema syndrome: episode of otherwise unexplained hiccups or nausea and vomiting"
 * - "Acute brainstem syndrome"
 * - "Symptomatic narcolepsy or acute diencephalic clinical syndrome with NMOSD-typical diencephalic MRI lesions"
 * - "Symptomatic cerebral syndrome with NMOSD-typical brain lesions"
 *
 * Muutoin tulos on "Ei tiedossa"
 * @param d: INMOSDCriteria
 * @returns: TYesNoUnknown
 */
export const atLeastTwoCoreClinicalCharacteristicsOccuring = (d: INMOSDCriteria): TYesNoUnknown => {
  const fields = [
    'opticNeuritis',
    'acuteMyelitis',
    'areaPostremaSyndrome',
    'acuteBrainstemSyndrome',
    'symptomaticNarcolepsy',
    'symptomaticCerebralSyndrome',
  ];
  if (numberOfGivenAnswers(d, fields, 'yes') >= 2) return 'yes';
  if (numberOfGivenAnswers(d, fields, 'no') >= 5) return 'no';
  return 'unknown';
};

/**
 * Ohjelma päättelee tämän edellä olevien vastausten perusteella. Vaatiiko "acute myelitis with LETM" lisäkysymyksen edellä olevaan osioon?
 *
 * "Kyllä" tulos edellyttää , että
 * - "Optic neuritis"="Kyllä", tai
 * - "Acute myelitis"="Kyllä", tai
 * - "Area postrema syndrome: episode of otherwise unexplained hiccups or nausea and vomiting"="Kyllä"
 *
 * "Ei" tulos edellyttää , että
 * - "Optic neuritis"="Ei", ja
 * - "Acute myelitis"="Ei", ja
 * - "Area postrema syndrome: episode of otherwise unexplained hiccups or nausea and vomiting"="Ei"
 *
 * Muutoin tulos on "Ei tiedossa"
 */
export const atLeastOneCoreClinicalCharacteristicWithoutAQP4 = (d: INMOSDCriteria): TYesNoUnknown => {
  if (d.opticNeuritis === 'yes') return 'yes';
  if (d.acuteMyelitis === 'yes') return 'yes';
  if (d.areaPostremaSyndrome === 'yes') return 'yes';
  if (d.opticNeuritis === 'no' && d.acuteMyelitis === 'no' && d.areaPostremaSyndrome === 'no') return 'no';
  return 'unknown';
};

/**
 * "Fulfillment of additional MRI requirements" =
 *
 * "Kyllä" edellyttää, että
 * - [jos "Acute optic neuritis"="Kyllä", niin
 *           "Acute optic neuritis: requires brain MRI showing
 *            (a) normal findings or only nonspecific white matter lesions, OR
 *            (b) optic nerve MRI with T2-hyperintense lesion or T1-weighted
 *                gadoliniumenhancing lesion extending over >1/2 optic nerve length or involving optic chiasm" on "Kyllä",
 *      ja
 * - jos "Acute myelitis"="Kyllä", niin
 *       "Acute myelitis: requires associated intramedullary MRI lesion extending over $3 contiguous segments (LETM) OR
 *        ≥3 contiguous segments of focal spinal cord atrophy in patients with history compatible with acute myelitis" on "Kyllä",
 *      ja
 * - jos "Area postrema syndrome"="Kyllä", niin
 *        "Area postrema syndrome: requires associated dorsal medulla/area postrema lesions" on "Kyllä",
 *      ja
 * - jos "Acute brainstem syndrome"="Kyllä", niin
 *        "Acute brainstem syndrome: requires associated periependymal brainstem lesions" on "Kyllä"]
 * -  tai ("Acute optic neuritis"="Ei" ja  "Acute myelitis"="Ei" ja  "Area postrema syndrome"="Ei" ja "Acute brainstem syndrome"="Ei")
 *
 * "Ei" edellyttää, että
 * - jos "Acute optic neuritis"="Kyllä", niin
 *        "Acute optic neuritis: requires brain MRI showing
 *         (a) normal findings or only nonspecific white matter lesions, OR
 *         (b) optic nerve MRI with T2-hyperintense lesion or T1-weighted
 *             gadoliniumenhancing lesion extending over >1/2 optic nerve length or involving optic chiasm" on "Ei",
 *      ja
 * - jos "Acute myelitis"="Kyllä", niin
 *       "Acute myelitis: requires associated intramedullary MRI lesion extending over $3 contiguous segments (LETM) OR
 *        ≥3 contiguous segments of focal spinal cord atrophy in patients with history compatible with acute myelitis" on "Ei",
 *     ja
 * - jos "Area postrema syndrome"="Kyllä", niin
 *       "Area postrema syndrome: requires associated dorsal medulla/area postrema lesions" on "Ei",
 *    ja
 * - jos "Acute brainstem syndrome"="Kyllä", niin
 *       "Acute brainstem syndrome: requires associated periependymal brainstem lesions" on "Ei"
 *
 * on muutoin "Ei tiedossa"
 * @param d: INMOSDCriteria
 * @returns: TYesNoUnknown
 */
export const fullfillmentOfAdditionalMRI = (d: INMOSDCriteria): TYesNoUnknown => {
  if (
    d.opticNeuritis === 'yes' &&
    d.acuteOpticNeuritisRequires === 'yes' &&
    d.acuteMyelitis === 'yes' &&
    d.acuteMyelitisRequires === 'yes' &&
    d.areaPostremaSyndrome === 'yes' &&
    d.areaPostremaSyndromeRequires === 'yes' &&
    d.acuteBrainstemSyndrome === 'yes' &&
    d.acuteBrainstemSyndromeRequires === 'yes'
  )
    return 'yes';
  if (
    d.opticNeuritis === 'no' &&
    d.acuteMyelitis === 'no' &&
    d.areaPostremaSyndrome === 'no' &&
    d.acuteBrainstemSyndrome === 'no'
  )
    return 'yes';
  if (
    d.opticNeuritis === 'yes' &&
    d.acuteOpticNeuritisRequires === 'no' &&
    d.acuteMyelitis === 'yes' &&
    d.acuteMyelitisRequires === 'no' &&
    d.areaPostremaSyndrome === 'yes' &&
    d.areaPostremaSyndromeRequires === 'no' &&
    d.acuteBrainstemSyndrome === 'yes' &&
    d.acuteBrainstemSyndromeRequires === 'no'
  )
    return 'no';
  return 'unknown';
};

/**
 * Ohjelma päättelee tämä edellä olevien vastausten perusteella.
 * "Kyllä" edellyttää, että "AQP4-IcG result using best available detection method (cell-based assay strongly recommended)"="Negative" tai "Ei tiedossa"
 * "Ei" edellyttää, että "AQP4-IcG result using best available detection method (cell-based assay strongly recommended)"="Positive"
 * @param d: INMOSDCriteria
 * @returns: TYesNoUnknown
 */
export const negativeTestsForAQP4 = (d: INMOSDCriteria): TYesNo => {
  if (d.AQP4IgGResult === 'negative' || d.AQP4IgGResult === 'unknown') return 'yes';
  if (d.AQP4IgGResult === 'positive') return 'no';
  return 'no';
};

/**
 * Ohjelma päättelee tämä edellä olevien vastausten perusteella:
 *
 * "Kyllä" tulos edellyttää , että:
 * - "At least 2 core clinical characteristics occurring as a result of one or more clinical attacks"="Kyllä", ja
 * - "At least 1 core clinical characteristic must be optic neuritis, acute myelitis with LETM, or area postrema syndrome"="Kyllä", ja
 * - "Dissemination in space (2 or more different core clinical characteristics)"="Kyllä", ja
 * - "Fulfillment of additional MRI requirements"="Kyllä", ja
 * - "Negative tests for AQP4-IgG using best available detection method, or testing unavailable"="Kyllä", ja
 * - "Exclusion of alternative diagnoses"="Kyllä"
 *
 * "Ei" tulos edellyttää , että:
 * - "At least 2 core clinical characteristics occurring as a result of one or more clinical attacks"="EI", tai
 * - "At least 1 core clinical characteristic must be optic neuritis, acute myelitis with LETM, or area postrema syndrome"="Ei", tai
 * - "Dissemination in space (2 or more different core clinical characteristics)"="Ei", tai
 * - "Fulfillment of additional MRI requirements"="Ei", tai
 * - "Negative tests for AQP4-IgG using best available detection method, or testing unavailable"="Ei", tai
 * - "Exclusion of alternative diagnoses"="Ei"
 *
 * Muutoin tulos on "Ei tiedossa"
 * @param d: INMOSDCriteria
 * @returns: TYesNoUnknown
 */
export const diagnosisCriteriaAreFullfilledWithoutAQP4 = (d: INMOSDCriteria): TYesNoUnknown => {
  if (
    atLeastTwoCoreClinicalCharacteristicsOccuring(d) === 'yes' &&
    atLeastOneCoreClinicalCharacteristicWithoutAQP4(d) === 'yes' &&
    d.disseminationInSpace === 'yes' &&
    fullfillmentOfAdditionalMRI(d) === 'yes' &&
    negativeTestsForAQP4(d) === 'yes' &&
    d.exclusionOfAlternativeDiagnosesWithoutAQP4IgG === 'yes'
  )
    return 'yes';
  if (
    atLeastTwoCoreClinicalCharacteristicsOccuring(d) === 'no' ||
    atLeastOneCoreClinicalCharacteristicWithoutAQP4(d) === 'no' ||
    d.disseminationInSpace === 'no' ||
    fullfillmentOfAdditionalMRI(d) === 'no' ||
    negativeTestsForAQP4(d) === 'no' ||
    d.exclusionOfAlternativeDiagnosesWithoutAQP4IgG === 'no'
  )
    return 'no';
  return 'unknown';
};

/**
 * Piilotetaan nämä kysymykset, jos "AQP4-IcG result using best available detection method (cell-based assay strongly
 * recommended)" on "Negatiivinen" tai "Ei tiedossa".
 * @param d: INMOSDCriteria
 * @returns: boolean
 */
export const showNOMSDWithAQP4 = (d: INMOSDCriteria): boolean => {
  if (d.AQP4IgGResult === 'negative' || d.AQP4IgGResult === 'unknown') return false;
  return true;
};

/**
 * Piilotetaan nämä kysymykset, jos "AQP4-IcG result using best available detection method (cell-based assay strongly
 * recommended)" on "Positiivinen".
 * @param d: INMOSDCriteria
 * @returns: boolean
 */
export const showNOMSDWithoutAQP4 = (d: INMOSDCriteria): boolean => {
  if (d.AQP4IgGResult === 'positive') return false;
  return true;
};
