import { includes, keys, length, pick, pickBy } from 'ramda';

/**
 * Jos "Tyypilliset MS-oirejaksot"=2, näytetään kaikki kolme vastausvaihtoehtoa".
 * Jos "Tyypilliset MS-oirejaksot"=1, näytetään vain vastausvaihtoehdot "1" ja "2".
 * @param d: IMSCriteria
 */
export const clinicalEvidenceOpts = (d: IMSCriteria): Array<string> => {
  if (d.typicalMSSymptomPeriod === '1') return ['1', '2'];
  if (d.typicalMSSymptomPeriod === '2') return ['1', '2', '3'];
  return [];
};

/**
 * Piilotetaan tämä kysymys, jos
 * - "Tyypilliset MS-oirejaksot"=1 tai "Objektiivinen kliininen* tai parakliininen** näyttö (MK, OCT, VEP),
 *    mikä liittyy nykyoireeseen tai aiempaan pahenemis-vaiheeseen ja viittaa tiettyyn anatomiseen alueeseen"=1
 * @param d: IMSCriteria
 */
export const showExcludedDifferentialDiagnosis = (d: IMSCriteria): boolean => {
  if (d.typicalMSSymptomPeriod === '1' || d.clinicalEvidence === '1a') return false;
  return true;
};

/**
 * Number of certain fields in document with values equal to given answer
 * @param d: IMSCriteria
 * @param fields: Array<string>
 * @param ans: TYesNoUnknown
 */
const numberOfGivenAnswers = (d: IMSCriteria, fields: Array<string>, ans: TYesNoUnknown): number =>
  length(keys(pickBy((v) => v === ans, pick(fields, d))));

/**
 * Paikallinen hajapesäkkeisyys (DIS) =
 * - "Kyllä" edellyttää, että "Uusi oirejakso uudelta anatomiselta alueelta"="Kyllä" tai
 *   "yksi tai useampi paikallinen MK-muutos vähintään kahdessa seuraavista sijainneista" on "Kyllä"
 *    vähintään kahdelle seuraavista: periventrikulaarinen alue, kortikaalinen tai jukstakortikaalinen alue, infratentoriaalinen alue, selkäytimen alue.
 * - "Ei" edellyttää, että "Uusi oirejakso uudelta anatomiselta alueelta"="Ei" ja
 *   "yksi tai useampi paikallinen MK-muutos vähintään kahdessa seuraavista sijainneista" on "Ei"
 *    vähintään kolmelle seuraavista: periventrikulaarinen alue, kortikaalinen tai jukstakortikaalinen alue, infratentoriaalinen alue, selkäytimen alue.
 * Muutoin vastaus on "Ei tiedossa".
 * @param d: IMSCriteria
 */
export const DISResult = (d: IMSCriteria): TYesNoUnknown => {
  const subFields = ['DISPeriventricularArea', 'DISCorticalArea', 'DISInfratentorialArea', 'DISSpinalCordArea'];
  if (d.DISNewSymptom === 'yes' || numberOfGivenAnswers(d, subFields, 'yes') >= 2) return 'yes';
  if (d.DISNewSymptom === 'no' && numberOfGivenAnswers(d, subFields, 'no') >= 3) return 'no';
  return 'unknown';
};

/**
 * Piilotetaan nämä kysymykset, jos
 * - "Tyypilliset MS-oirejaksot"=2 ja
 *   ["Objektiivinen kliininen* tai parakliininen** näyttö (MK, OCT, VEP), mikä liittyy nykyoireeseen tai aiempaan pahenemis-vaiheeseen ja viittaa tiettyyn anatomiseen alueeseen"=2 tai
 *   "Objektiivinen kliininen* tai parakliininen** näyttö (MK, OCT, VEP), mikä liittyy nykyoireeseen tai aiempaan pahenemis-vaiheeseen ja viittaa tiettyyn anatomiseen alueeseen"="1
 *   (myös esitieto aiemmasta oirejaksosta, joka on kliinisillä löydöksillä edelleen yhdistettävissä erilliseen anatomiseen alueeseen)"], tai
 * - "Tyypilliset MS-oirejaksot"=1
 * @param d: IMSCriteria
 */
export const showDISQuestions = (d: IMSCriteria): boolean => {
  if (d.typicalMSSymptomPeriod === '2' && includes(d.clinicalEvidence, ['1b', '2'])) return false;
  if (d.typicalMSSymptomPeriod === '1') return false;
  return true;
};

/**
 * Ajallinen hajapesäkkeisyys (DIT) =
 * - Kyllä edellyttää, että "Uusi oirejakso"="Kyllä" tai "Uusi MK-muutos"="Kyllä" tai "Likvorissa oligoklonaalisia jaokkeita"="Kyllä"
 * - Ei edellyttää, että "Uusi oirejakso"="Ei" ja "Uusi MK-muutos"="Ei" ja "Likvorissa oligoklonaalisia jaokkeita"="Ei",
 * - Muutoin tulos on "Ei tiedossa"
 * @param d: IMSCriteria
 */
export const DITResult = (d: IMSCriteria): TYesNoUnknown => {
  if (d.DITNewSymptom === 'yes' || d.DITNewMKChange === 'yes' || d.DITLOFracttions === 'yes') return 'yes';
  if (d.DITNewSymptom === 'no' && d.DITNewMKChange === 'no' && d.DITLOFracttions === 'no') return 'no';
  return 'unknown';
};

/**
 * Piilotetaan nämä kysymykset, jos
 * - "Tyypilliset MS-oirejaksot"=2 tai
 * "Objektiivinen kliininen* tai parakliininen** näyttö (MK, OCT, VEP), mikä liittyy nykyoireeseen tai aiempaan pahenemis-vaiheeseen ja viittaa tiettyyn anatomiseen alueeseen"=1
 * @param d: IMSCriteria
 * @returns boolean
 */
export const showDITQuestions = (d: IMSCriteria): boolean => {
  if (d.typicalMSSymptomPeriod === '2' || d.clinicalEvidence === '1a') return false;
  return true;
};

/**
 * Paikallinen hajapesäkkeisyys (DIS) =
 * - "Kyllä" edellyttää, että
 *      "Uudelta anatomiselta alueelta joko uusi oirejakso"="Kyllä"
 *        tai
 *      "uusi MK-muutos" on "Kyllä"
 * - "Ei" edellyttää, että
 *      "Uudelta anatomiselta alueelta joko uusi oirejakso"="Ei"
 *        ja
 *      "uusi MK-muutos" on "Ei"
 * Muutoin vastaus on "Ei tiedossa".
 *
 * Ajallinen hajapesäkkeisyys (DIT) =
 * - Kyllä edellyttää, että
 *    "Uusi oirejakso"=Kyllä
 *      tai
 *    "MK:ssa ajallinen hajapesäkkeisyys"=Kyllä
 *      tai
 *    "Likvorissa oligoklonaalisia jaokkeita"=Kyllä
 * - Ei edellyttää, että
 *    "Uusi oirejakso"=Ei
 *      ja
 *    "MK:ssa ajallinen hajapesäkkeisyys"=Ei
 *      ja
 *    "Likvorissa oligoklonaalisia jaokkeita"=Ei,
 * - Muutoin tulos on "Ei tiedossa"
 * @param d: IMSCriteria
 * @returns TYesNoUnknown
 */
export const DISAndDITResult = (d: IMSCriteria): TYesNoUnknown => {
  if (
    (d.DISAndDITAnatomicNewSymptom === 'yes' || d.DISAndDITNewMKChange === 'yes') &&
    (d.DITAndDISNewSymptom === 'yes' || d.DITAndDISTimedMKScatteredColony === 'yes' || d.DITAndDISLOsegment === 'yes')
  )
    return 'yes';
  if (
    (d.DISAndDITAnatomicNewSymptom === 'no' && d.DISAndDITNewMKChange === 'no') ||
    (d.DITAndDISNewSymptom === 'no' && d.DITAndDISTimedMKScatteredColony === 'no' && d.DITAndDISLOsegment === 'no')
  )
    return 'no';
  return 'unknown';
};

/**
 * Piilotetaan nämä kysymykset, jos
 * - "Tyypilliset MS-oirejaksot"=2  tai
 * "Objektiivinen kliininen* tai parakliininen** näyttö (MK, OCT, VEP), mikä liittyy nykyoireeseen tai aiempaan pahenemis-vaiheeseen ja viittaa tiettyyn anatomiseen alueeseen"=2
 * @param d: IMSCriteria
 * @returns boolean
 */
export const showDISAndDITQuestions = (d: IMSCriteria): boolean => {
  if (d.typicalMSSymptomPeriod === '2' || d.clinicalEvidence === '2') return false;
  return true;
};

/**
 * Ohjelma päättelee tämän edellä olevien vastausten perusteella:
 *
 * "Kyllä" tulos edellyttää, että
 * - "Tyypilliset MS-oirejaksot"=2
 *      ja
 *    ["Objektiivinen kliininen* tai parakliininen** näyttö (MK, OCT, VEP), mikä liittyy nykyoireeseen tai aiempaan pahenemis-vaiheeseen ja viittaa tiettyyn anatomiseen alueeseen"=2
 *        tai
 *     "Objektiivinen kliininen* tai parakliininen** näyttö (MK, OCT, VEP), mikä liittyy nykyoireeseen tai aiempaan pahenemis-vaiheeseen ja viittaa tiettyyn anatomiseen alueeseen"="1 (myös esitieto aiemmasta oirejaksosta, joka on kliinisillä löydöksillä edelleen yhdistettävissä erilliseen anatomiseen alueeseen)"]
 *       ja
 *     "Erotusdiagnostiset vaihtoehdot on suljettu pois"="Kyllä",
 * tai
 * - "Tyypilliset MS-oirejaksot"=2
 *      ja
 *   "Objektiivinen kliininen* tai parakliininen** näyttö (MK, OCT, VEP), mikä liittyy nykyoireeseen tai aiempaan pahenemis-vaiheeseen ja viittaa tiettyyn anatomiseen alueeseen"=1
 *      ja
 *   "Paikallinen hajapesäkkeisyys (DIS)"="Kyllä",
 * tai
 * - "Tyypilliset MS-oirejaksot"=1
 *      ja
 *   "Objektiivinen kliininen* tai parakliininen** näyttö (MK, OCT, VEP), mikä liittyy nykyoireeseen tai aiempaan pahenemis-vaiheeseen ja viittaa tiettyyn anatomiseen alueeseen"=2
 *      ja
 *   "Ajallinen hajapesäkkeisyys (DIT)"="Kyllä",
 * tai
 * - "Tyypilliset MS-oirejaksot"=1
 *      ja
 *   "Objektiivinen kliininen* tai parakliininen** näyttö (MK, OCT, VEP), mikä liittyy nykyoireeseen tai aiempaan pahenemis-vaiheeseen ja viittaa tiettyyn anatomiseen alueeseen"=1
 *      ja
 *   "Paikallinen hajapesäkkeisyys (DIS)"="Kyllä"
 *      ja
 *   "Ajallinen hajapesäkkeisyys (DIT)"="Kyllä" - Huom! Paikallisen ja ajallisen hajapesäkkeisyyden määritelmät ovat tässä erit kuin edellä!
 *
 * "Ei" tulos edellyttää, että
 * - "Tyypilliset MS-oirejaksot"=2
 *      ja
 *   ["Objektiivinen kliininen* tai parakliininen** näyttö (MK, OCT, VEP), mikä liittyy nykyoireeseen tai aiempaan pahenemis-vaiheeseen ja viittaa tiettyyn anatomiseen alueeseen"=2
 *        tai
 *    "Objektiivinen kliininen* tai parakliininen** näyttö (MK, OCT, VEP), mikä liittyy nykyoireeseen tai aiempaan pahenemis-vaiheeseen ja viittaa tiettyyn anatomiseen alueeseen"="1 (myös esitieto aiemmasta oirejaksosta, joka on kliinisillä löydöksillä edelleen yhdistettävissä erilliseen anatomiseen alueeseen)"]
 *      ja
 *   "Erotusdiagnostiset vaihtoehdot on suljettu pois"="Ei",
 * tai
 * - "Tyypilliset MS-oirejaksot"=2
 *      ja
 *   "Objektiivinen kliininen* tai parakliininen** näyttö (MK, OCT, VEP), mikä liittyy nykyoireeseen tai aiempaan pahenemis-vaiheeseen ja viittaa tiettyyn anatomiseen alueeseen"=1
 *      ja
 *   "Paikallinen hajapesäkkeisyys (DIS)"="Ei",
 * tai
 * - "Tyypilliset MS-oirejaksot"=1
 *      ja
 *   "Objektiivinen kliininen* tai parakliininen** näyttö (MK, OCT, VEP), mikä liittyy nykyoireeseen tai aiempaan pahenemis-vaiheeseen ja viittaa tiettyyn anatomiseen alueeseen"=2
 *      ja
 *   "Ajallinen hajapesäkkeisyys (DIT)"="Ei",
 * tai
 * - "Tyypilliset MS-oirejaksot"=1
 *      ja
 *   "Objektiivinen kliininen* tai parakliininen** näyttö (MK, OCT, VEP), mikä liittyy nykyoireeseen tai aiempaan pahenemis-vaiheeseen ja viittaa tiettyyn anatomiseen alueeseen"=1
 *      ja
 *   ["Paikallinen hajapesäkkeisyys (DIS)"="Ei"
 *        tai
 *    "Ajallinen hajapesäkkeisyys (DIT)"="Ei"] - Huom! Paikallisen ja ajallisen hajapesäkkeisyyden määritelmät ovat tässä erit kuin edellä!
 *
 * Muutoin tulos on "Ei tiedossa".
 * @param d: IMSCriteria
 * @returns TYesNoUnknown
 */
export const diagnosticCriteriaForRR = (d: IMSCriteria): TYesNoUnknown => {
  if (
    d.typicalMSSymptomPeriod === '2' &&
    (d.clinicalEvidence === '2' || d.clinicalEvidence === '1b') &&
    d.excludedDifferentialDiagnosis === 'yes'
  )
    return 'yes';
  if (d.typicalMSSymptomPeriod === '2' && d.clinicalEvidence === '1a' && DISResult(d) === 'yes') return 'yes';
  if (d.typicalMSSymptomPeriod === '1' && d.clinicalEvidence === '2' && DITResult(d) === 'yes') return 'yes';
  if (d.typicalMSSymptomPeriod === '1' && d.clinicalEvidence === '1a' && DISAndDITResult(d) === 'yes') return 'yes';

  if (
    d.typicalMSSymptomPeriod === '2' &&
    (d.clinicalEvidence === '2' || d.clinicalEvidence === '1b') &&
    d.excludedDifferentialDiagnosis === 'no'
  )
    return 'no';
  if (d.typicalMSSymptomPeriod === '2' && d.clinicalEvidence === '1a' && DISResult(d) === 'no') return 'no';
  if (d.typicalMSSymptomPeriod === '1' && d.clinicalEvidence === '2' && DITResult(d) === 'no') return 'no';
  if (
    d.typicalMSSymptomPeriod === '1' &&
    d.clinicalEvidence === '1a' &&
    ((showDISQuestions(d) && DISResult(d) === 'no') || (showDISAndDITQuestions(d) && DISAndDITResult(d) === 'no'))
  )
    return 'no';

  return 'unknown';
};

/**
 * "MK:ssa yksi tai useampi paikallinen MS-muutos vähintään yhdessä seuraavista sijainneista:" on "Kyllä", jos yksi seuraavista on "Kyllä":  periventrikulaarinen alue, kortikaalinen tai jukstakortikaalinen alue, infratentoriaalinen alue.
 * "MK:ssa yksi tai useampi paikallinen MS-muutos vähintään yhdessä seuraavista sijainneista:" on "Ei", jos kaikki seuraavista on "Ei":  periventrikulaarinen alue, kortikaalinen tai jukstakortikaalinen alue, infratentoriaalinen alue.
 * Muutoin "MK:ssa yksi tai useampi paikallinen MS-muutos vähintään yhdessä seuraavista sijainneista:" on "Ei tiedossa"
 * @param d: IMSCriteria
 * @returns TYesNoUnknown
 */
export const OneOrMoreMSChangesInMK = (d: IMSCriteria): TYesNoUnknown => {
  const fields = ['periventicularArea', 'corticalArea', 'infratentorialArea'];
  if (numberOfGivenAnswers(d, fields, 'yes') >= 1) return 'yes';
  if (numberOfGivenAnswers(d, fields, 'no') === 3) return 'no';
  return 'unknown';
};

/**
 * Ohjelma päättelee tämän edellä olevien vastausten perusteella:
 *
 * "Kyllä" tulos edellyttää, että
 * - "Taudin kliinisen haitan eteneminen yhden vuoden aikana; retrospektiivinen tai prospektiivinen arvio (esim. EDSS) oirejaksoista riippumatta"="Kyllä", ja
 * - [("MK:ssa yksi tai useampi paikallinen MS-muutos vähintään yhdessä seuraavista sijainneista:" on "Kyllä" ja "selkäytimen MK:ssa vähintään kaksi T2-hyperintensiivistä muutosta" = "Kyllä"), tai
 * - ("MK:ssa yksi tai useampi paikallinen MS-muutos vähintään yhdessä seuraavista sijainneista:" on "Kyllä" ja "likvorissa vähintään 2 oligoklonaalista jaoketta" = "Kyllä"), tai
 * -  ("selkäytimen MK:ssa vähintään kaksi T2-hyperintensiivistä muutosta" = "Kyllä" ja "likvorissa vähintään 2 oligoklonaalista jaoketta" = "Kyllä")]
 *
 * "Ei" tulos edellyttää, että
 * - "Taudin kliinisen haitan eteneminen yhden vuoden aikana; retrospektiivinen tai prospektiivinen arvio (esim. EDSS) oirejaksoista riippumatta"="Ei", tai
 * - [("MK:ssa yksi tai useampi paikallinen MS-muutos vähintään yhdessä seuraavista sijainneista:" on "Ei" ja "selkäytimen MK:ssa vähintään kaksi T2-hyperintensiivistä muutosta" = "Ei"), tai
 * - ("MK:ssa yksi tai useampi paikallinen MS-muutos vähintään yhdessä seuraavista sijainneista:" on "Ei" ja "likvorissa vähintään 2 oligoklonaalista jaoketta" = "Ei"), tai
 * -  ("selkäytimen MK:ssa vähintään kaksi T2-hyperintensiivistä muutosta" = "Ei" ja "likvorissa vähintään 2 oligoklonaalista jaoketta" = "Ei")]
 *
 * Muutoin tulos on "Ei tiedossa".
 * @param d: IMSCriteria
 * @returns TYesNoUnknown
 */
export const diagnosticCriteriaForPP = (d: IMSCriteria): TYesNoUnknown => {
  if (d.progressionOfDisease === 'yes') {
    if (
      OneOrMoreMSChangesInMK(d) === 'yes' &&
      (d.changesInSpinalCord === 'yes' || d.oligoclonalFractionsInLiquid === 'yes')
    )
      return 'yes';
    if (d.changesInSpinalCord === 'yes' && d.oligoclonalFractionsInLiquid === 'yes') return 'yes';
  }
  if (d.progressionOfDisease === 'no') return 'no';
  if (OneOrMoreMSChangesInMK(d) === 'no') {
    if (d.changesInSpinalCord === 'no') return 'no';
    if (d.oligoclonalFractionsInLiquid === 'no') return 'no';
  }
  if (d.changesInSpinalCord === 'no' && d.oligoclonalFractionsInLiquid === 'no') return 'no';
  return 'unknown';
};

/**
 * Make an array of mcDonald2017 RR and PP criteria answers for history view to draw
 * @param d: IMSCriteria
 * @returns Array<string>
 */
export const mcDonald2017ResultForHistoryView = (d: IMSCriteria): Array<string> => {
  const RR = diagnosticCriteriaForRR(d);
  const PP = diagnosticCriteriaForPP(d);
  const resultArr: Array<string> = [];
  if (RR === 'yes') resultArr.push('rr.yes');
  if (PP === 'yes') resultArr.push('pp.yes');
  if (RR !== 'yes' && PP !== 'yes') {
    resultArr.push(`rr.${RR}`);
    resultArr.push(`pp.${PP}`);
  }
  return resultArr;
};
