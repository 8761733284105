import * as React from 'react';

import FormRow from '../../../../../components/FormRow';
import FormSection from '../../../../../components/FormSection';
import InputHandler from '../../../../../components/InputHandler';
import { useIntl } from 'react-intl';

const PassTestForm = ({ formData }: IOwnProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const fm = (s: string) => formatMessage({ id: s });
  return (
    <FormSection>
      <FormRow title="general.date">
        <InputHandler
          type="PartialDate"
          editing={true}
          name="date"
          formData={formData}
          dateDefault="now"
          isNotCancellable={true}
        />
      </FormRow>
      <FormRow title="functionalPerformanceMgravis.labels.passTest.satisfiedWithCurrentSituation">
        <InputHandler
          type="Radio"
          name="satisfiedWithCurrentSituation"
          editing={true}
          formData={formData}
          options={['yes', 'no']}
          optionFormatter={(o) => fm(`general.${o}`)}
        />
      </FormRow>
    </FormSection>
  );
};

interface IOwnProps {
  formData: IFormData<IPassTest>;
}

export default PassTestForm;
