import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Item } from '../../../../components/Grid';
import HistoryRowControls from '../../../../components/HistoryRowControls';
import HistoryRowListing from '../../../../components/HistoryRowListing';
import { formatPartialDate } from 'neuro-utils';
import HistoryHeader from 'Components/_NewElements/HistoryHeader';

const PassTestHistory = ({ documents, startEdit }: IOwnProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const fm = (s: string) => formatMessage({ id: s });
  return (
    <>
      <HistoryHeader
        headerText={<FormattedMessage id="functionalPerformanceMgravis.passTest" />}
        buttons={[{ text: 'functionalPerformanceMgravis.newPassTest', onClick: startEdit({}, 'passTest'), width: 12 }]}
      />
      <HistoryRowListing
        documents={documents}
        headers={
          <>
            <Item xs={2}>
              <FormattedMessage id="general.date" />
            </Item>
            <Item xs={8}>
              <FormattedMessage id="functionalPerformanceMgravis.labels.passTest.satisfiedWithCurrentSituation" />
            </Item>
            <Item xs={2}>&nbsp;</Item>
          </>
        }
        contentFormat={(d: IPassTest): JSX.Element => (
          <>
            <Item xs={2}>{formatPartialDate(d.date ?? undefined)}</Item>
            <Item xs={2}>{fm(`general.${d.satisfiedWithCurrentSituation}`)}</Item>
            <Item xs={5}>&nbsp;</Item>
            <Item xs={3}>
              <HistoryRowControls document={d} startEdit={startEdit} />
            </Item>
          </>
        )}
      />
    </>
  );
};

interface IOwnProps {
  documents: Array<IPassTest>;
  startEdit: (document: TAnyObject, name?: string) => (e: React.MouseEvent<Element, MouseEvent>) => void;
  view?: IView;
}

export default PassTestHistory;
