import * as React from 'react';
import { useAppSelector as useSelector } from 'Store/index';
import { makeLog } from 'Utility/logger';
import {
  calculateDaysDifference,
  nowPartialDate,
  partialDateFromDate,
  partialDateToValue,
  sortPartialDate,
} from 'neuro-utils';
import { LocalizerCtx } from 'Containers/Localizer';
import FormRow from 'Components/FormRow';
import InputHandler from 'Components/InputHandler';
import { MyServiceContext } from '..';
import { StyledTextArea } from 'Components/InputHandler/components/TextArea';
import { surveysByPlatform } from '../config';
import { equals } from 'ramda';
import colors from '../../../../config/theme/colors';

export type TaskListFormProps = {
  [platform in Platform]?: TaskStimulusFormSettings;
};

const dayMillis = 86400000;

/**
 * Questionnaires that MUST be ordered by a doctor before they can be included
 * in the patient sendable notification.
 *
 * @requirement NEUR-2679
 */
const orderRequiredQuestionnaires = ['gad-7', 'bai'];

const TaskListForm = ({ taskListSettings }: { taskListSettings?: TaskListFormProps }) => {
  const myServContext = React.useContext(MyServiceContext);
  const { fm, editing, setEditingData, platform } = myServContext;
  const reduxDocs = useSelector((s: IState) => s.documents.sortedAndMergedDocuments);
  const latestContraIndicationDocument = reduxDocs
    ?.filter((d) => d._type === 'contraIndicationsToTreatment')
    .sort((a, b) => sortPartialDate(b.date, a.date))?.[0];
  const platformSettings = platform ? taskListSettings?.[platform] : undefined;
  const { locale } = React.useContext(LocalizerCtx);
  const [selectedPlatform, setSelectedPlatform] = React.useState<Platform | undefined>(platform);
  const isMountRender = React.useRef<boolean>(true);
  const isMountRender2 = React.useRef<boolean>(true);

  const taskLists = useSelector((state: IState) => state.myapp?.taskLists);
  const platformSpecificTasklists = taskLists?.filter(
    (tl) => tl.tasks?.every((t) => platform && surveysByPlatform[platform]?.includes(t)),
  );
  const currentRecurringPlatformSpecificTasklists = platformSpecificTasklists?.filter(
    (tl) => tl.recurring && (!tl.deadline || partialDateToValue(tl?.deadline) > partialDateToValue(nowPartialDate())),
  );

  const taskListData = editing?.data as unknown as ITaskList;

  const orderedQuestionnaires =
    reduxDocs?.find((d) => d._type === 'doctorsOrdersAdditional')?.additionalDocuments ?? [];

  const tdcsAndRtmsDocs = reduxDocs
    ?.filter((d) => d._type === 'rtms' || d._type === 'tdcs')
    ?.sort((d1, d2) => sortPartialDate(d1?.date, d2?.date));

  const treatmentPeriodDocs = reduxDocs?.filter((d) => d._type === 'ninmtTreatmentPeriod');

  const latestTreatmentPeriod = treatmentPeriodDocs?.sort((a, b) => sortPartialDate(b?.date, a?.date))[0];
  const latestTreatmentPeriodDate = latestTreatmentPeriod?.date;

  const activeTdcsOrRtms = tdcsAndRtmsDocs?.find(
    (d) => partialDateToValue(d?.date) >= partialDateToValue(latestTreatmentPeriodDate) && !d?.hasEnded,
  );

  const disabledOptions =
    platform && surveysByPlatform[platform]
      ? surveysByPlatform[platform]?.filter(
          (q) =>
            (orderRequiredQuestionnaires.includes(q) && !(orderedQuestionnaires as string[]).includes(q)) ||
            (q === 'contraIndicationsInquiry' && !activeTdcsOrRtms),
        )
      : undefined;

  const recurringDisabledOpts = currentRecurringPlatformSpecificTasklists
    ?.filter((tl) => tl.id !== taskListData?.id)
    ?.flatMap((tl) => tl.tasks);

  const onChangeTaskForm = (values: TOnChangeValues | Array<TOnChangeValues>): void => {
    if (Array.isArray(values)) {
      const changes = values.map((v) => {
        const field = Object.keys(v)[0];
        const value = Object.values(v)[0];
        return { [field]: value };
      });
      setEditingData?.(Object.assign({}, taskListData, ...changes));
    } else {
      const field = Object.keys(values)[0];
      const value = Object.values(values)[0];
      setEditingData?.(Object.assign({}, taskListData, { [field]: value }));
    }
  };

  // When title changed:
  // - select tasks that are included in that titles taskSettings
  // - set information of that title based on taskSettings
  // - set sendDate based on 'sendDateDaysBeforeDeadline' field on taskSettings
  // - if recurring and recurringDeadline, taskSettngs 'recurringTaskDeadlineDefaultDays' sets deadline
  // - if taskSettings with selected title has 'recurring' field true, set recurring true AND vice versa
  // - if taskSettings with selected title has 'recurringInterval' field, set interval, if not set undefined
  React.useEffect(() => {
    if (isMountRender.current) {
      isMountRender.current = false;
      return;
    }
    if (!platform) return;
    const changeableFieldsValues: Array<TOnChangeValues> = [];
    if (taskListData?.title) {
      const titleSelectedTasks = platformSettings?.[taskListData.title]?.defaultSelectQuestionnaires;
      const selectableTasks = titleSelectedTasks
        ?.filter((t) => surveysByPlatform[platform]?.includes(t))
        .filter(
          (t) =>
            !disabledOptions?.includes(t) &&
            (taskListData?.recurring ? !(recurringDisabledOpts as string[])?.includes(t) : true),
        );
      const newInformation = locale
        ? platformSettings?.[taskListData.title].descriptionLocalized?.[locale] ??
          platformSettings?.[taskListData.title].descriptionLocalized?.['fi']
        : undefined;
      if (!equals(titleSelectedTasks, taskListData?.tasks)) {
        changeableFieldsValues.push({ tasks: selectableTasks });
      }
      if (newInformation !== taskListData?.information) {
        changeableFieldsValues.push({ information: newInformation ?? '' });
      }
      if (
        (taskListData?.deadline || taskListData?.recurringTaskDeadline) &&
        platformSettings?.[taskListData?.title].sendDateDaysBeforeDeadline
      ) {
        const deadlineToValue = partialDateToValue(taskListData?.recurringTaskDeadline || taskListData?.deadline);
        let sendingDate =
          deadlineToValue - dayMillis * (platformSettings?.[taskListData?.title]?.sendDateDaysBeforeDeadline ?? 1);
        if (sendingDate < partialDateToValue(nowPartialDate())) {
          sendingDate = partialDateToValue(nowPartialDate());
        }
        if (sendingDate !== partialDateToValue(taskListData?.sendDate)) {
          changeableFieldsValues.push({ sendDate: partialDateFromDate(new Date(sendingDate)) });
        }
      }
      if (
        taskListData?.recurring &&
        taskListData?.recurringTaskDeadline &&
        platformSettings?.[taskListData.title]?.recurringTaskDeadlineDefaultDays
      ) {
        const defaultRecurringEndDate = platformSettings?.[taskListData.title]?.recurringTaskDeadlineDefaultDays;
        changeableFieldsValues.push({
          deadline: partialDateFromDate(
            new Date(
              partialDateToValue(taskListData?.recurringTaskDeadline) + dayMillis * (defaultRecurringEndDate ?? 1),
            ),
          ),
        });
      }
      if (platformSettings?.[taskListData.title]?.recurring && !taskListData?.recurring) {
        changeableFieldsValues.push({
          recurring: true,
        });
      }
      if (!platformSettings?.[taskListData.title]?.recurring && taskListData?.recurring) {
        changeableFieldsValues.push({
          recurring: false,
        });
      }
      if (
        platformSettings?.[taskListData.title]?.recurringInterval &&
        ['weekly', 'everyOtherWeek', 'everyFourthWeek'].includes(
          platformSettings[taskListData.title].recurringInterval ?? '',
        ) &&
        taskListData?.weeklySurveyInterval !== platformSettings?.[taskListData.title]?.recurringInterval
      ) {
        changeableFieldsValues.push({
          weeklySurveyInterval: platformSettings?.[taskListData.title]?.recurringInterval,
        });
      }
      if (!platformSettings?.[taskListData.title]?.recurringInterval && taskListData?.weeklySurveyInterval) {
        changeableFieldsValues.push({
          weeklySurveyInterval: undefined,
        });
      }
    }
    if (changeableFieldsValues.length > 0) {
      onChangeTaskForm(changeableFieldsValues);
    }
  }, [taskListData?.title]);

  // When deadline changed, set sendDate based on orgSettings and not earlier than now
  // And if latest contraIndicationsInq is >30 days ago, auto-select it
  React.useEffect(() => {
    if (taskListData?.deadline && !taskListData?.recurring) {
      const changeableFieldsValues: Array<TOnChangeValues> = [];
      const taskSettingsSendDateBeforeDeadline = taskListData?.title
        ? platformSettings?.[taskListData?.title]?.sendDateDaysBeforeDeadline
        : undefined;
      if (taskSettingsSendDateBeforeDeadline) {
        const deadlineValue = partialDateToValue(taskListData?.deadline);
        const newSendDateValue = deadlineValue - dayMillis * taskSettingsSendDateBeforeDeadline;
        let newSendDate: PartialDate;
        if (newSendDateValue < partialDateToValue(nowPartialDate())) {
          newSendDate = nowPartialDate();
        } else {
          newSendDate = partialDateFromDate(new Date(newSendDateValue));
        }
        changeableFieldsValues.push({ sendDate: newSendDate });
      }
      if (
        (calculateDaysDifference(latestContraIndicationDocument?.date, taskListData?.deadline) ?? 1) > 30 &&
        platform &&
        surveysByPlatform[platform]?.includes('contraIndicationsInquiry') &&
        !taskListData?.tasks?.includes('contraIndicationsInquiry') &&
        !disabledOptions?.includes('contraIndicationsInquiry')
      ) {
        changeableFieldsValues.push({ tasks: [...(taskListData?.tasks ?? []), 'contraIndicationsInquiry'] });
      }
      if (changeableFieldsValues.length > 0) {
        onChangeTaskForm(changeableFieldsValues);
      }
    }
  }, [taskListData?.deadline]);

  // When recurring and changing recurringTaskDeadline, set sendDate based on that and no earlier than now
  // and set deadline to recurringTaskDeadline + (taskSettings 'recurringTaskDeadlineDefaultDays' * dayMillis)
  React.useEffect(() => {
    if (isMountRender2.current) {
      isMountRender2.current = false;
      return;
    }
    if (taskListData?.recurring && taskListData?.recurringTaskDeadline) {
      const changeableFieldsValues: Array<TOnChangeValues> = [];
      const taskSettingsSendDateBeforeDeadline = taskListData?.title
        ? platformSettings?.[taskListData?.title]?.sendDateDaysBeforeDeadline
        : undefined;
      if (taskSettingsSendDateBeforeDeadline) {
        const recurringDeadlineValue = partialDateToValue(taskListData?.recurringTaskDeadline);
        const newSendDateValue = recurringDeadlineValue - dayMillis * taskSettingsSendDateBeforeDeadline;
        let newSendDate: PartialDate;
        if (newSendDateValue < partialDateToValue(nowPartialDate())) {
          newSendDate = nowPartialDate();
        } else {
          newSendDate = partialDateFromDate(new Date(newSendDateValue));
        }
        changeableFieldsValues.push({ sendDate: newSendDate });
        // onChangeTaskForm({ sendDate: newSendDate });
      }
      if (taskListData?.title && platformSettings?.[taskListData.title]?.recurringTaskDeadlineDefaultDays) {
        changeableFieldsValues.push({
          deadline: partialDateFromDate(
            new Date(
              partialDateToValue(taskListData?.recurringTaskDeadline) +
                dayMillis * (platformSettings?.[taskListData.title]?.recurringTaskDeadlineDefaultDays ?? 1),
            ),
          ),
        });
      }
      if (changeableFieldsValues.length > 0) {
        onChangeTaskForm(changeableFieldsValues);
      }
    }
  }, [taskListData?.recurringTaskDeadline]);

  // Empty title, tasks and information fields if platform is changed
  React.useEffect(() => {
    if (platform !== selectedPlatform) {
      onChangeTaskForm([{ tasks: undefined }, { information: '' }, { title: undefined }]);
      setSelectedPlatform(platform);
    }
  }, [platform]);

  React.useEffect(() => {
    if (isMountRender.current) {
      isMountRender.current = false;
      return;
    }
    if (
      taskListData?.tasks?.includes('ninmtPreInquiry') &&
      (taskListData?.recurringTaskDeadline || taskListData?.deadline)
    ) {
      const deadlineToValue = partialDateToValue(taskListData?.recurringTaskDeadline || taskListData?.deadline);
      const currentSendDateValue = taskListData?.sendDate ? partialDateToValue(taskListData?.sendDate) : undefined;
      let sendingDate = deadlineToValue - dayMillis * 14;
      if (sendingDate < partialDateToValue(nowPartialDate())) {
        sendingDate = partialDateToValue(nowPartialDate());
      } else if (currentSendDateValue && sendingDate > currentSendDateValue) {
        sendingDate = currentSendDateValue;
      }
      onChangeTaskForm({ sendDate: partialDateFromDate(new Date(sendingDate)) });
    }
  }, [taskListData?.tasks]);

  // When selecting recurring, uncheck tasks which are already in some recurring stimulus
  // Reset recurring fields if recurring ticked off
  React.useEffect(() => {
    const changeableFieldsValues: Array<TOnChangeValues> = [];
    if (taskListData?.recurring && recurringDisabledOpts && recurringDisabledOpts.length > 0) {
      if (isMountRender.current || taskListData?.id) {
        isMountRender.current = false;
        return;
      }
      changeableFieldsValues.push({ tasks: taskListData?.tasks?.filter((t) => !recurringDisabledOpts.includes(t)) });
    }
    if (!taskListData?.recurring) {
      changeableFieldsValues.push({ recurringTaskDeadline: undefined });
      changeableFieldsValues.push({ weeklySurveyInterval: undefined });
    }
    if (changeableFieldsValues.length > 0) {
      onChangeTaskForm(changeableFieldsValues);
    }
  }, [taskListData?.recurring]);

  if (!locale) {
    makeLog('Warning', new Error('Attempted to use TaskListForm without locale being set!'));
    return <></>;
  }

  return (
    <>
      <FormRow title="myService.taskListTitle">
        <InputHandler
          name="title"
          type="Select"
          editing={!!editing}
          options={
            platformSettings && Object.keys(platformSettings).length > 0 ? Object.keys(platformSettings) : ['other']
          }
          optionFormatter={(o) =>
            platformSettings && Object.keys(platformSettings).length > 0
              ? `${platformSettings?.[o]?.titleLocalized[locale] ?? platformSettings?.[o]?.titleLocalized['fi']}`
              : fm(`myService.opts.${o}`)
          }
          formData={{
            document: { title: taskListData?.title },
            onChange: onChangeTaskForm,
          }}
          placeholder="myService.taskListTitlePlaceholder"
          fittedWidth
        />
      </FormRow>
      {taskListData && taskListData.title && platformSettings?.[taskListData.title]?.titleDescLocalized?.[locale] && (
        <FormRow>
          {platformSettings?.[taskListData.title]?.titleDescLocalized?.[locale] ??
            platformSettings?.[taskListData.title]?.titleDescLocalized?.['fi']}
        </FormRow>
      )}
      <FormRow title="myService.recurring" condition={platform === 'ninmt'}>
        <InputHandler
          name="recurring"
          type="CheckboxSingle"
          option="true"
          showLabel={false}
          editing={!!editing}
          formData={{
            document: { recurring: taskListData?.recurring },
            onChange: () => {
              onChangeTaskForm([{ weeklySurveyInterval: undefined }, { recurring: !taskListData?.recurring }]);
            },
          }}
        />
      </FormRow>
      <FormRow title="myService.recurringInterval" condition={!!taskListData?.recurring}>
        <InputHandler
          name="weeklySurveyInterval"
          type="Select"
          editing={!!editing}
          options={['weekly', 'everyOtherWeek', 'everyFourthWeek']}
          placeholder={'myService.recurringInterval'}
          optionFormatter={(o) => fm(`myService.opts.${o}`)}
          formData={{
            document: { weeklySurveyInterval: taskListData?.weeklySurveyInterval },
            onChange: onChangeTaskForm,
          }}
        />
      </FormRow>
      <FormRow title="myService.recurringTaskDeadline" condition={!!taskListData?.recurring}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div>
            <InputHandler
              name="recurringTaskDeadline"
              type="PartialDate"
              editing={!!editing}
              formData={{
                document: { recurringTaskDeadline: taskListData?.recurringTaskDeadline },
                onChange: onChangeTaskForm,
              }}
              dateHook={{ dateHookFloor: nowPartialDate(), dateHookCeiling: taskListData?.deadline }}
            />
          </div>
          {taskListData?.recurringTaskDeadline && (
            <span style={{ paddingBottom: 5 }}>
              {fm(`general.jsDate.${new Date(partialDateToValue(taskListData?.recurringTaskDeadline)).getDay()}`)}
            </span>
          )}
        </div>
      </FormRow>
      <FormRow title={'myService.deadline'} condition={!taskListData?.recurring}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div>
            <InputHandler
              name="deadline"
              type="PartialDate"
              editing={!!editing}
              formData={{ document: { deadline: taskListData?.deadline }, onChange: onChangeTaskForm }}
              dateHook={{ dateHookFloor: nowPartialDate() }}
              isNotCancellable
            />
          </div>
          {taskListData?.deadline && (
            <span style={{ paddingBottom: 5 }}>
              {fm(`general.jsDate.${new Date(partialDateToValue(taskListData?.deadline)).getDay()}`)}
            </span>
          )}
        </div>
      </FormRow>
      <FormRow title="myService.sendDate">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div>
            <InputHandler
              name="sendDate"
              type="PartialDate"
              editing={!!editing}
              formData={{
                document: { sendDate: taskListData?.sendDate },
                onChange: onChangeTaskForm,
              }}
              dateHook={{
                dateHookCeiling: taskListData?.recurringTaskDeadline ?? taskListData?.deadline,
                dateHookFloor: partialDateFromDate(new Date()),
              }}
              isNotCancellable={true}
            />
          </div>
          {taskListData?.sendDate && (
            <span style={{ paddingBottom: 5 }}>
              {fm(`general.jsDate.${new Date(partialDateToValue(taskListData?.sendDate)).getDay()}`)}
            </span>
          )}
        </div>
      </FormRow>
      {/** Deadline row second time as fields wanted in different order depending on if recurred checked */}
      <FormRow title={'myService.lastRecurringTaskDeadline'} condition={!!taskListData?.recurring}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div>
            <InputHandler
              name="deadline"
              type="PartialDate"
              editing={!!editing}
              formData={{ document: { deadline: taskListData?.deadline }, onChange: onChangeTaskForm }}
              dateHook={{ dateHookFloor: nowPartialDate() }}
              isNotCancellable
            />
          </div>
          {taskListData?.deadline && (
            <span style={{ paddingBottom: 5 }}>
              {fm(`general.jsDate.${new Date(partialDateToValue(taskListData?.deadline)).getDay()}`)}
            </span>
          )}
        </div>
      </FormRow>
      <FormRow title="myService.tasksForTaskList">
        <InputHandler
          name="tasks"
          type="Checkbox"
          editing={!!editing}
          options={
            platform && surveysByPlatform[platform]
              ? surveysByPlatform[platform]
                  ?.flatMap((s) => (s === 'pedsql' ? ['pedsqlNeuromuscular', 'pedsqlFatigue'] : s))
                  .filter((surv) => (platform === 'parkinson' ? surv !== 'mdsUpdrsIV' : surv))
              : []
          }
          optionFormatter={(o) => {
            const addAsterisk = taskListData?.recurring && (recurringDisabledOpts as string[])?.includes(`${o}`);
            if (o === 'contraIndicationsInquiry' && !taskListData?.recurring) {
              return `${fm(`myService.ninmt.opts.${o}`)} ${fm(
                'myService.ninmt.opts.contraIndicationsInquiryInfo',
                calculateDaysDifference(latestContraIndicationDocument?.date, taskListData?.deadline) ?? 'X',
              )}${addAsterisk ? '*' : ''}`;
            }
            return `${fm(`myService.${platform}.opts.${o}`)}${addAsterisk ? '*' : ''}`;
          }}
          formData={{
            document: {
              tasks: taskListData?.tasks,
            },
            onChange: onChangeTaskForm,
          }}
          disabledOptions={
            taskListData?.recurring
              ? [...(disabledOptions ?? []), ...((recurringDisabledOpts as string[]) ?? [])]
              : disabledOptions
          }
        />
        {taskListData?.recurring && recurringDisabledOpts && recurringDisabledOpts.length > 0 ? (
          <div style={{ color: colors.tertiaryText }}>{fm('myService.alreadyRecurring')}</div>
        ) : (
          <></>
        )}
      </FormRow>
      <FormRow title="myService.taskListInfo">
        <StyledTextArea
          name="information"
          value={taskListData?.information}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
            onChangeTaskForm({ information: e.currentTarget.value })
          }
          placeholder={fm('myService.taskListInfoPlaceholder')}
          rows={14}
        />
      </FormRow>
    </>
  );
};

export default TaskListForm;
