import FormRow from 'Components/FormRow';
import FormSection from 'Components/FormSection';
import InputHandler from 'Components/InputHandler';
import QuestionStepper from 'Components/QuestionStepper';
import { IFormContext, withFormContext } from 'Containers/FormContextHandler';
import { steps as madrsSteps, getStepContent as getMadrsSteps } from '../madrsSettings';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { madrsTotalScore } from 'Routes/InterviewSurveys/utils';
import colors from '../../../../../config/theme/colors';

const MADRS = ({ formData, view /*, documents*/ }: IFormContext<IMADRS, any>): JSX.Element => {
  return (
    <React.Fragment>
      <FormRow title="general.date">
        <InputHandler
          type="PartialDate"
          editing={!view?.viewing}
          name="date"
          formData={formData}
          dateDefault="now"
          isNotCancellable
        />
      </FormRow>
      <FormSection>
        <QuestionStepper
          viewing={!!view?.viewing}
          formData={formData}
          pageType={'interviewSurveys'}
          docType={'madrs'}
          steps={madrsSteps}
          getStepContent={getMadrsSteps}
        />
      </FormSection>
      <FormRow
        title="interviewSurveys.madrs.totalScore"
        description={<FormattedMessage id="interviewSurveys.madrs.totalScoreDescription" values={{ br: <br /> }} />}
      >
        <span style={{ fontWeight: 600 }}>
          {madrsTotalScore(formData.document) === 'notCounted' ? '-' : madrsTotalScore(formData.document)}
        </span>
      </FormRow>

      <FormRow title="interviewSurveys.madrs.details">
        <InputHandler
          type="TextArea"
          editing={!view?.viewing}
          name="details"
          placeholder={'interviewSurveys.madrs.detailsPlaceholder'}
          formData={formData}
        />
      </FormRow>

      <div style={{ fontStyle: 'italic', color: colors.darkGray }}>
        <FormattedMessage id="interviewSurveys.madrs.kaypaHoito" />
      </div>
    </React.Fragment>
  );
};

export default withFormContext(MADRS);
