import * as React from 'react';
import HistoryRowListing from '../../../../components/HistoryRowListing';
import { IHistoryContext, withFormContext } from '../../../../containers/FormContextHandler';
import { formatRowData, isISeizureFreePeriod } from '../../utils';
import HistoryRowControls from '../../../../components/HistoryRowControls';
import { Container, Item } from '../../../../components/Grid';
import { calculateIntervalYMD, nowPartialDate } from 'neuro-utils';
import { sortDocuments } from '../../../../utility/randomUtil';
import ActionButtonRounded from 'Components/ActionButtonRounded';

const itemSpace = 4;

/** Make a JSX row from a seizureFreePeriod document. */
const makeRow = (d: ISeizureFreePeriod): JSX.Element => {
  const { seizureFreePeriodDetails, startDate, endDate } = d;
  const endingDate = endDate ? endDate : nowPartialDate();
  const diff = startDate && calculateIntervalYMD(startDate, endingDate);
  const data = {
    seizureFreePeriod: [startDate, endDate],
    seizureFreePeriodLength: startDate ? diff : undefined,
    seizureFreePeriodDetails,
  };
  const dataRow: Array<React.ReactNode> = [];
  (Object.keys(data) as Array<keyof typeof data>).forEach((key) => {
    const value = data[key];
    dataRow.push(
      <Item key={key} xs={itemSpace}>
        {formatRowData(key, value)}
      </Item>,
    );
  });
  return <Container>{dataRow}</Container>;
};

/** Make a JSX header row for seizureFreePeriod documents. */
const makeHeaders = (texts: string[]): JSX.Element => {
  const headerRow: Array<React.ReactNode> = texts.map((t, idx) => (
    <Item key={idx} xs={itemSpace}>
      {t}
    </Item>
  ));
  headerRow.push(<Item key="tail" xs={4} />);
  return <Container>{headerRow}</Container>;
};

const SeizureFreePeriods = ({ documents, fm, startEdit }: IOwnProps): JSX.Element => {
  if (!fm || !startEdit) throw 'fm or startEdit is undefined';
  const seizFreePerDocs = documents ? (documents.filter((d) => isISeizureFreePeriod(d)) as ISeizureFreePeriod[]) : [];
  const headerTexts = ['period', 'seizureFreePeriodLength', 'seizureFreePeriodDetails'].map((t) => fm(`seizure.${t}`));

  seizFreePerDocs.sort((a, b) => sortDocuments([{ type: 'date', sortField: 'date' }])(a, b));
  return (
    <>
      <div
        style={{
          display: 'flex',
          flex: 1,
          justifyContent: 'flex-end',
          marginBottom: '2rem',
        }}
      >
        <ActionButtonRounded
          text={'seizure.addNewSeizureFreePeriod'}
          onClick={startEdit && startEdit({}, 'seizureFreePeriod')}
          filled
          uppercase={false}
          width={20}
          height={4}
          fontSize={16}
        />
      </div>
      <HistoryRowListing
        contentFormat={makeRow}
        headers={makeHeaders(headerTexts)}
        documents={seizFreePerDocs}
        dateFieldName="startDate"
        makeControls
        historyRowControls={(d: IControlProps) => <HistoryRowControls document={d} startEdit={startEdit} />}
      />
    </>
  );
};

interface IOwnProps extends IHistoryContext {
  documents?: Array<ISeizureDoc>;
}

export default withFormContext(SeizureFreePeriods);
