import DataTable from 'Components/DataTable';
import { Container, Item } from 'Components/Grid';
import HistoryRowSubHeader from 'Components/HistoryRowSubHeader';
import { INeuroStimulus } from 'neuro-data-structures';
import { formatPartialDate, partialDateFromDate } from 'neuro-utils';
import * as React from 'react';
import { useAppSelector as useSelector } from 'Store/index';
import { MyServiceContext } from '..';
import ActionButtonRounded from 'Components/ActionButtonRounded';

const InvitesHistory = ({
  invites,
  inviteErrors,
}: {
  invites: Array<INeuroStimulus>;
  inviteErrors: Array<{ method: 'sms' | 'email'; statusCode: number }>;
}): JSX.Element => {
  const myServContext = React.useContext(MyServiceContext);
  const { fm, setEditingObj } = myServContext;

  const orgs = useSelector((s: IState) => s.session.orgGroupArray);

  const invitesData = invites
    .toSorted((a, b) => b.timestamp - a.timestamp)
    .map((i) => [
      formatPartialDate(partialDateFromDate(new Date(i.timestamp))),
      fm(`myService.opts.${i.medium === 'EMAIL' ? 'email' : 'sms'}`),
      i.carrier ? i.carrier : '',
      i.org_id ? orgs?.find((o) => o.orgId === i.org_id)?.displayName || i.org_id : '',
    ]);

  return (
    <>
      <Container style={{ justifyContent: 'space-between' }}>
        <Item>
          <HistoryRowSubHeader header={fm('myService.invites')} />
        </Item>

        <Item>
          <ActionButtonRounded
            text="myService.newInvite"
            filled={true}
            uppercase={false}
            width={9.6}
            height={3}
            fontSize={16}
            onClick={() => {
              setEditingObj({
                type: 'invite',
                data: null,
              });
            }}
          />
        </Item>
      </Container>
      <DataTable
        headers={['sendingDate', 'sendingMedium', 'numberOrAddress', 'sendingOrg']}
        headersFormatter={(h) => h && fm(`myService.${h}`)}
        data={{ rowData: invitesData }}
      />
      <div style={{ color: 'red', marginTop: '2rem' }}>
        {inviteErrors.map(
          (err) =>
            err.statusCode === 429 && (
              <div key={err.method}>
                {fm('errors.invites.' + err.method)}
                {' - '}
                {fm('errors.invites.' + err.statusCode)}
              </div>
            ),
        )}
      </div>
    </>
  );
};

export default InvitesHistory;
